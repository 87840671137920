// © Microsoft Corporation. All rights reserved.

import {
    WebWorkerMessageId,
    environment,
    initializeMessageHandler,
    loadConfigTree,
    perfMarker,
    registerCommandMessageHandler,
    registerConfigResolverTasks,
    registerDataMessageHandler,
    registerOneServiceAuthHeaderListener,
    registerOneServiceFeedTasks
} from "@msnews/web-worker";

(() => {
    // since we can't use perfMarker until after environment is initialized,
    // capture the raw time offset at start now, so we can use it after init.
    const startTime = performance.now();

    // Initialize environment variables
    environment.init();

    // record with the custom time we saved earlier once environment has been fully initialized.
    perfMarker("Start", startTime);

    // Add message listener first to avoid missing messages from main thread
    initializeMessageHandler();

    // Register auth header listener from main thread so that WW 1S calls can be personalized
    registerOneServiceAuthHeaderListener();

    if (environment.currentFlights.includes("prg-hp-fvrf")) {
        // Register tasks dependent on/should wait for other tasks here
        registerConfigResolverTasks();
        registerOneServiceFeedTasks();
        // Start independent tasks here
        loadConfigTree();
    }

    // register empty handlers for messages that are hard coded in entrypoint since it is a common class
    // without these an appError is triggered on every page
    registerCommandMessageHandler(WebWorkerMessageId.UpdateMyBriefing, (data) => { /* noop */ });

    registerCommandMessageHandler(WebWorkerMessageId.PostTtvr, () => { /* noop */ });

    registerDataMessageHandler(WebWorkerMessageId.MyFeed, () => { /* noop */ });
})();
